// @ts-nocheck
import _ from 'lodash'
import React, { useState, useContext, useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { StoreContext } from 'contexts/StoreContext'
import Select from 'components/common/SelectField'
import styled, { css } from 'styled-components/macro'
import TextField from 'components/common/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonBase from '../common/ButtonBase'
import IconComponent from '../../themes/IconComponent'
import CTAText from 'components/common/CTAText'
import TypographyPro from 'themes/TypographyPro'
import queryString from 'query-string'
import {
	codeToLocale,
	getGT,
	isMenuPage,
	sendRequest,
	getTranslatedTextByKey,
	injectVarsToTemplateString,
	removeDiscountByCalcGt,
} from '../../utils/utils'
import { CONSTANTS } from '../../utils/constants'
import { z } from 'zod'
import storage from 'utils/storage'

const EMPTY_HOLDER = { id: '', name: '' }
const MIN_ID_LENGTH = 3
const MIN_NAME_LENGTH = 5
const NAME_REGEX = /^(?:[a-zA-Z\s]*[a-zA-Z]){5}$/
const NUMERIC_REGEX = /^[0-9]*$/

const SpecialDiscountHeader = styled(TypographyPro)`
	${({ $disabled }) =>
		$disabled &&
		css`
			color: var(--inactive);
		`}
`

const StyledTrashCanIcon = styled(IconComponent).attrs({
	asset: '/icons/trashCan.svg',
})`
	cursor: pointer;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 35px;
	right: 7px;
`

const ButtonBaseStyled = styled(ButtonBase)`
	margin-top: 20px;
`

const AddMoreLimitMessage = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
`

const CTATextStyled = styled(CTAText)`
	${({ $disabled }) =>
		$disabled &&
		css`
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				opacity: 0;
				cursor: not-allowed;
			}
		`}
	position: relative;
	margin-top: 15px;
`

const SpecialDiscountWrapper = styled.div`
	${({ $disabled }) =>
		$disabled &&
		css`
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				opacity: 0;
				cursor: not-allowed;
			}
		`}
	position: relative;
	margin-top: 20px;
`

const StyledSelect = styled(Select)`
	border-radius: 2px;
`

const IdNameContainer = styled.div`
	display: flex;
	gap: 5px;
	margin-top: 15px;
	position: relative;
`

const TextFieldIdWrapper = styled.div`
	width: 30%;
`

const TextFieldNameWrapper = styled.div`
	width: calc(70% - 36px);
`

const idValidationSchema = z
	.string({ required_error: getTranslatedTextByKey('webviewFlow.cannotBeEmpty', 'Cannot be empty') })
	.min(1, { message: getTranslatedTextByKey('webviewFlow.cannotBeEmpty', 'Cannot be empty') })
	.regex(NUMERIC_REGEX, { message: 'Only numbers are allowed' })
	.min(MIN_ID_LENGTH, {
		message: injectVarsToTemplateString(
			`${getTranslatedTextByKey('webviewFlow.mustBeAtLeast', `Must be at least ${MIN_ID_LENGTH} characters long`)}`,
			{
				'#limit': MIN_ID_LENGTH,
			}
		),
	})
	.trim()

const nameValidationSchema = z
	.string({ required_error: getTranslatedTextByKey('webviewFlow.cannotBeEmpty', 'Cannot be empty') })
	.min(1, { message: getTranslatedTextByKey('webviewFlow.cannotBeEmpty', 'Cannot be empty') })
	.regex(NAME_REGEX, { message: `At least ${MIN_NAME_LENGTH} letters long, only letters are allowed` })
	.trim()

const SpecialDiscount = inject(
	'Cart',
	'Application',
	'User',
	'Infra'
)(
	observer((props) => {
		const { store } = useContext(StoreContext)
		const rest = store.data
		const { Cart, Application, User, Infra } = props
		const [discountType, setDiscountType] = useState('')
		const [holders, setHolders] = useState([])
		const locale = (User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest?.locale) ?? 'en_US'
		const { serverSpecialDiscountsIdLimit } = Cart
		const { specialDiscounts } = User.session
		const displaySpecialDiscounts = specialDiscounts?.enabled
		const specialDiscountCode = specialDiscounts?.code
		const specialDiscountTypes = specialDiscounts?.types
		const disabledAddMore = useMemo(() => holders.length >= serverSpecialDiscountsIdLimit, [holders, serverSpecialDiscountsIdLimit])
		const disabledSpecialDiscount = useMemo(
			() => Cart.serverDisableCouponsField || Cart.serverDisableSpecialDiscounts,
			[Cart.serverDisableCouponsField, Cart.serverDisableSpecialDiscounts]
		)
		const isDiscountsLimitQuantityValid = holders.length <= serverSpecialDiscountsIdLimit

		const showDiscountsLimitError = () => {
			Infra.showSnackbar({
				message: getTranslatedTextByKey(
					'webviewFlow.coupons.discountLimitErrorMessage',
					`You can only add up to {numOfDiscounts} discounts`
				).replace('{numOfDiscounts}', serverSpecialDiscountsIdLimit),
				status: 'error',
				isAttachedToElement: false,
			})
		}

		useEffect(() => {
			if (!isDiscountsLimitQuantityValid) {
				showDiscountsLimitError()
			}
		}, [Cart.serverSpecialDiscountsIdLimit])

		const [applyClicked, setApplyClicked] = useState(false)

		const _getGTFromServer = async () => (await getGT(Cart.items, rest.items, isMenuPage())) ?? {}

		const handleSpecialDiscountSuccess = () => {
			setHolders([])
			setDiscountType('')
			setApplyClicked(false)
		}

		const sendSpecialDiscountCode = async (discountValueFromCache) => {
			Infra.setLoading(true)

			if (Cart.discounts[specialDiscountCode]) {
				return
			}

			const qParams = queryString.parse(window.location.search)
			qParams.wru = qParams.wru ?? Infra.appParams?.wru ?? 'https://fb.tictuk.com/'
			qParams.cust = qParams.cust ?? Infra.appParams?.cust
			qParams.request = qParams.request ?? localStorage.getItem('sessionId')

			const discountValue =
				discountValueFromCache ||
				JSON.stringify({
					text: CONSTANTS.redeemDiscountKey,
					code: JSON.stringify({
						code: specialDiscountCode,
						integrationData: {
							type: discountType,
							holders,
						},
					}),
				})
			const _data = {
				value: discountValue,
				cust: qParams.cust,
				request: qParams.request,
			}

			try {
				const responseRedeem = await sendRequest(
					false,
					`${qParams.wru}check_field`,
					'post',
					JSON.stringify(_data),
					{
						Accept: 'application/json',
						'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
					},
					true,
					3000
				)
				if (responseRedeem?.error === true) {
					Infra.showSnackbar({
						snackId: 'cart',
						message: `${responseRedeem?.msg ?? ''}`,
						status: 'error',
						isAttachedToElement: false,
					})
					return
				}

				sessionStorage.setItem('appliedSpecialDiscounts', discountValue)
				const { gt, chargesFromServer, response, deliveryInfo } = await _getGTFromServer()
				removeDiscountByCalcGt(Cart, response.appliedDiscounts)
				Cart.setServerGrandTotal(gt)
				Cart.setServerCharges(chargesFromServer)
				Cart.setServerAddedItemsFromDiscounts(response.addedItemsFromDiscounts)
				Cart.setServerDeliveryInfo(deliveryInfo)
				Cart.setServerSpecialDiscount(response)
				handleSpecialDiscountSuccess()

				const lastDiscount = chargesFromServer.findLast(({ type }) => type === 'discount')
				if (lastDiscount) {
					Cart.addDiscount(lastDiscount, storage)
				}

				if (discountValueFromCache) {
					return
				}

				Infra.showSnackbar({
					snackId: 'cart',
					message: `${responseRedeem.discount.title[locale]} ${getTranslatedTextByKey('wasAddedToCart')}`,
					status: 'success',
					isAttachedToElement: false,
				})
			} catch (err) {
				console.error(err)
			} finally {
				Infra.setLoading(false)
			}
		}

		if (!displaySpecialDiscounts || Application.page !== 'checkout') {
			return null
		}

		const getIdError = (id: string, acceptEmpty = !applyClicked): { message: string } | undefined => {
			if (acceptEmpty && !id) {
				return null
			}

			return idValidationSchema.safeParse(id).error?.issues?.[0]
		}

		const getNameError = (id: string, acceptEmpty = !applyClicked): { message: string } | undefined => {
			if (acceptEmpty && !id) {
				return null
			}

			return nameValidationSchema.safeParse(id).error?.issues?.[0]
		}

		const areAllFieldsValidForSending = () => holders.every(({ id, name }) => !getIdError(id, false) && !getNameError(name, false))

		const onApply = () => {
			if (!isDiscountsLimitQuantityValid) {
				showDiscountsLimitError()
				return
			}

			setApplyClicked(true)

			if (!areAllFieldsValidForSending()) {
				return
			}

			return sendSpecialDiscountCode()
		}

		const handleKeyDown = (e) => {
			if (e.key === 'Enter') {
				onApply()
			}
		}

		const handleChangeDiscountType = (event) => {
			const { value } = event.target

			setDiscountType(value)
			setApplyClicked(false)
			if (!value) {
				setHolders([])
				return
			}
			if (!holders.length) {
				setHolders([{ ...EMPTY_HOLDER }])
			}
		}

		return (
			<SpecialDiscountWrapper $disabled={disabledSpecialDiscount}>
				<StyledSelect
					disabled={disabledSpecialDiscount}
					onChange={handleChangeDiscountType}
					value={discountType}
					displayEmpty
					disableUnderline
					fieldHeight={37}
					label={
						<SpecialDiscountHeader component="span" variant="BodyRegularHighlighted" $disabled={disabledSpecialDiscount}>
							Select Senior or PWD Discount
						</SpecialDiscountHeader>
					}
					id={`${Application.page}-page-choose-special-discount`}
				>
					<MenuItem key="" value="" id={`${Application.page}-page-choose-special-discount-option`}>
						{getTranslatedTextByKey('webviewFlow.coupons.specialDiscountsDropdownHint', '')}
					</MenuItem>
					{specialDiscountTypes.map((discount) => (
						<MenuItem key={discount.id} value={discount.id} id={`${Application.page}-page-choose-special-discount-option`}>
							{discount.text[locale]}
						</MenuItem>
					))}
				</StyledSelect>
				{!disabledSpecialDiscount && (
					<>
						{holders.map(({ id, name }, index) => (
							<IdNameContainer>
								<TextFieldIdWrapper>
									<TextField
										label={
											<TypographyPro component="span" variant="BodySmallHighlighted">
												{getTranslatedTextByKey('webviewFlow.coupons.specialDiscountIds', '')}
											</TypographyPro>
										}
										value={id}
										error={getIdError(id)}
										errorMessage={getIdError(id)?.message}
										placeholder="Type your id"
										id={`${Application.page}-page-special-discount-id-input-${index}`}
										onChange={(event) => {
											const { value } = event.target
											setHolders((prevState) => {
												const newState = _.cloneDeep(prevState)
												newState[index].id = value
												return newState
											})
										}}
										inputProps={{
											onKeyDown: handleKeyDown,
											'data-testid': 'person-id',
										}}
									/>
								</TextFieldIdWrapper>
								<TextFieldNameWrapper>
									<TextField
										label={
											<TypographyPro component="span" variant="BodySmallHighlighted">
												{getTranslatedTextByKey('webviewFlow.coupons.specialDiscountNames', '')}
											</TypographyPro>
										}
										value={name}
										error={getNameError(name)}
										errorMessage={getNameError(name)?.message}
										placeholder="Type your name"
										id={`${Application.page}-page-special-discount-name-input-${index}`}
										onChange={(event) => {
											const { value } = event.target
											setHolders((prevState) => {
												const newState = _.cloneDeep(prevState)
												newState[index].name = value
												return newState
											})
										}}
										inputProps={{
											onKeyDown: handleKeyDown,
											'data-testid': 'person-name',
										}}
									/>
								</TextFieldNameWrapper>
								<StyledTrashCanIcon
									id={`${Application.page}-page-special-discount-remove-${index}`}
									onClick={() => {
										if (!holders.length) {
											return
										}
										if (holders.length === 1) {
											setDiscountType('')
										}
										setHolders((prevState) => {
											const newState = _.cloneDeep(prevState)
											newState.splice(index, 1)
											return newState
										})
									}}
								/>
							</IdNameContainer>
						))}
						{holders.length ? (
							<>
								<CTATextStyled
									disabled={disabledAddMore}
									$disabled={disabledAddMore}
									type="button"
									$type="danger"
									onClick={() => {
										if (disabledAddMore) {
											return
										}
										setHolders((prevState) => {
											const newState = _.cloneDeep(prevState)
											newState.push({ ...EMPTY_HOLDER })
											return newState
										})
									}}
									id={`${Application.page}-page-special-discount-add-more-button`}
									data-testid="government-discount-add-another-id"
								>
									{getTranslatedTextByKey('webviewFlow.coupons.addSpecialDiscountId', '')}
								</CTATextStyled>
								{disabledAddMore ? (
									<AddMoreLimitMessage component="div" variant="BodyRegular">
										You applied maximum of discounts. Order something else if you want to apply more.
									</AddMoreLimitMessage>
								) : null}
								<ButtonBaseStyled $type="primary" onClick={onApply} id={`${Application.page}-page-special-discount-submit-button`}>
									{getTranslatedTextByKey('webviewFlow.coupons.applySpecialDiscount', '')}
								</ButtonBaseStyled>
							</>
						) : null}
					</>
				)}
			</SpecialDiscountWrapper>
		)
	})
)

export default SpecialDiscount
